import request from '@/utils/request'


// 查询会员权益列表
export function listEquity(query) {
  return request({
    url: '/vip/vip-equitys/list',
    method: 'get',
    params: query
  })
}

// 查询会员权益分页
export function pageEquity(query) {
  return request({
    url: '/vip/vip-equitys/page',
    method: 'get',
    params: query
  })
}

export function listNoBindEquity(query) {
  return request({
    url: '/vip/vip-equitys/listNoBindEquity',
    method: 'get',
    params: query
  })
}


// 查询会员权益详细
export function getEquity(data) {
  return request({
    url: '/vip/vip-equitys/detail',
    method: 'get',
    params: data
  })
}

// 新增会员权益
export function addEquity(data) {
  return request({
    url: '/vip/vip-equitys/add',
    method: 'post',
    data: data
  })
}

// 修改会员权益
export function updateEquity(data) {
  return request({
    url: '/vip/vip-equitys/edit',
    method: 'post',
    data: data
  })
}

// 删除会员权益
export function delEquity(data) {
  return request({
    url: '/vip/vip-equitys/delete',
    method: 'post',
    data: data
  })
}
