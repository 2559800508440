<template>
  <a-modal title='会员权益' :visible="visible" width="800px"
           @cancel="handleCancel"
           @ok="handleOk" cancelText='关闭'  >
    <div class="table-operations">
      <a-button type="danger" :disabled="multiple" @click="handleDelete" >
        <a-icon type="delete" />删除
      </a-button>
    </div>

    <a-table
      :loading="loading"
      :size="tableSize"
      rowKey="id"
      :columns="columns"
      :data-source="list"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :pagination="false"
      :bordered="tableBordered"
    >
      <!--      -->
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
      <span slot="action" slot-scope="text, record">

          <a @click="handleDelete(record)"  >
            <a-icon type="delete" />删除
          </a>
        </span>
    </a-table>
  </a-modal>
</template>

<script>

import { pageMenuEquity,listMenuEquity, delMenuEquity } from '@/api/vip/menuEquity'
import { tableMixin } from '@/store/table-mixin'
export default {
  name: 'GraphCategory',
  components: {

  },
  mixins: [tableMixin],
  data() {
    return {

      vipId:null,
      visible:false,
      multiple:false,
      list: [],
      // 展开的行，受控属性
      expandedRowKeys: [],
      replaceFields: {
        id: "id",
        pid: "pid",
        children: "childList"
      },
      sortParams: {
        field: "sort",
        way: "ASC"
      },
      selectedRowKeys: [],
      selectedRows: [],

      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        categoryName: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [

        {
          title: '权益名称',
          dataIndex: 'equityName',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '是否设置数值',
          dataIndex: 'isSetNum',
          ellipsis: true,
          align: 'center',
            customRender: function (value) {
              if(value == 1){
                return "是"
              }else {
                return "否"
              }
            }
        },
        {
          title: '数值',
          dataIndex: 'equityNum',
          ellipsis: true,
          align: 'center',

        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'action' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {

  },
  computed: {},
  watch: {},
  methods: {
    show(record){
      // this.selectedRowKeys = []
      // this.selectedRows = []
      this.visible = true;
      this.multiple = false
      this.vipId = record.id;
      this.queryParam.vipId = record.id;

      this.getVipEquityList();

    },
    /** 查询引用列表 */
    getVipEquityList() {
      this.loading = true
      listMenuEquity(this.queryParam).then(response => {
        this.list = response.data;
        this.total = response.data.total
        this.loading = false
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    handleOk(){
      this.visible = false
      this.$emit('ok')
    },
    handleCancel(){
      this.visible = false
      this.$emit('ok')
    },
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      if(this.ids.length<1){
        that.$message.error(
          '请选择数据',
          3
        );
        return
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delMenuEquity(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getVipEquityList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
  }
}
</script>
<style lang='less' scoped></style>