<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form"   :rules="rules">
      <a-form-model-item label="权益是否设置数值" prop="name" >
        <a-select v-model="isSetNum" placeholder="请选择" style="width: 100%" @change="handleChange" allow-clear>
          <a-select-option :value="0">否</a-select-option>
          <a-select-option :value="1">是</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="会员权益" prop="equityId" >

        <a-select v-if='isSetNum==0' mode="multiple" placeholder="请选择权益" v-model="equityIds" allow-clear >
          <a-select-option v-for="(item,index) in equityList"  :key="item.id">{{ item.name }}</a-select-option>
        </a-select>
        <a-select  v-if='isSetNum==1' placeholder="请选择权益" v-model="equityId" allow-clear >
          <a-select-option v-for="(item,index) in equityList"  :key="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if='isSetNum==1' label="权益数值" prop="equityNum" >
        <a-input-number v-model='equityNum' placeholder='请输入' :min='0' :max='9999999' :step='1'/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {  listNoBindEquity } from '@/api/vip/equity'
import {  addMenuEquity, addBatchMenuEquity} from '@/api/vip/menuEquity'
import Editor from '@/components/Editor'
import elColorPicker from 'el-color-picker'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor,elColorPicker
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      loading:false,
      isSetNum:0,
      equityNum:0,
      vipId:'',
      equityIds:undefined,
      equityId:undefined,
      equityList:[],
      // 表单参数

      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {

        vipContentColor: [
          { required: true, message: 'vip内容色值不能为空', trigger: 'blur' }
        ],

      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.isSetNum = 0;
      this.equityList=[]
      this.equityIds=undefined
      this.equityId =undefined

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.vipId = row.id
      this.formTitle = '添加'
      this.getEquityList();
    },


    getEquityList(){
      listNoBindEquity({isSetNum:this.isSetNum,vipId:this.vipId}).then(response => {
        this.equityList = response.data

      })
    },
    handleChange(value){
      if(this.isSetNum != value){
        this.equityNum = 0
      }
      this.isSetNum = value;
      this.getEquityList();
      console.log("====value=====",value)
    },
    /** 提交按钮 */
    submitForm: function () {
      let that = this
      this.submitLoading = true
      console.log("----------------")
      if (that.isSetNum == 0) {
        let data = []
        that.equityIds.map(id => data.push({vipId:that.vipId,equityId:id}))
        addBatchMenuEquity(data).then(response => {
          this.$message.success(
            '修改成功',
            3
          )
          this.open = false
          this.$emit('ok')
        }).finally(() => {
          this.submitLoading = false
        })
      } else {
        addMenuEquity({vipId:that.vipId,equityId:that.equityId,equityNum:that.equityNum }).then(response => {
          this.$message.success(
            '新增成功',
            3
          )
          this.open = false
          this.$emit('ok')
        }).finally(() => {
          this.submitLoading = false
        })
      }
    }
  }
}
</script>
